






















































import { Component, Vue, Prop, Mixins } from 'vue-property-decorator'
// import headerInfo from '../page/components/header.vue'
import headerInfo from './components/header.vue'
import IndexMixin from '../mixins/indexMixin'
import { getAnswerList } from '@/modules/exam/api/index'

@Component({
  components: {
    headerInfo
  }
})
export default class ClassWrap extends Mixins(IndexMixin) {
  // @Prop() id: any
  isLoading: boolean = false
  errorObj: any = null
  questionNum: any = 0
  pagedEngine: any = {}
  examMap: any = {
    radio: '单选题',
    checkbox: '多选题',
    judge: '判断题',
    essay: '问答题',
    mandarin: '口语题', // 普通话
    englishspoken: '口语题', // 英语
    sort: '排序题',
    singleblank: '填空题',
    mulitblank: '多元填空',
    optionblank: '选词填空',
    rich_text: '说明',
    combination: '阅读理解',
    listening: '听写题',
    typewriting: '打字题'
  }
  objectiveTypes: any = ['radio', 'checkbox', 'judge', 'sort', 'singleblank', 'optionblank'] // 客观题  只有对错
  sortNum: Number = 1

  created() {
    this.pageInit()
  }

  goBack() {
    this.$router.go(-1)
  }

  pageInit(){
    this.api(this.id, 1, 10)
  }

  formatItems(items: Array<any>) {
    let i = 1
    console.log(items instanceof Array, 'typeof items');
    if (items instanceof Array){
      items.forEach((x: any) => {
        if (x.type !== 'rich_text') {
          x.index = i++
        }
        x.topic = x.topic.replace(/<img.+?>/g, '[图片]').replace(/<audio.+?>/g, '[音频]').replace(/<video.+?>/g, '[视频]').replace(/(<.+?>)|(&nbsp;)|(&ensp;)|(&emsp;)/g, '')
      })
    }
    
    return items
  }

  api(id: any, page: any, size: any) {
    return getAnswerList(id, page, size).then((e: any) => {
      console.log(e, '打印解析列表');
      this.pagedEngine = e.data
      this.questionNum = e.page.total
    })
  }

  toAnalysis(id: any) {
    this.$router.push({
      name: 'exam',
      params: { id: this.id },
      query: {
        curSubId: id,
        renderType: 'analysis'
      }
    })
  }

  switchPages(e: any){
    this.sortNum = e
    this.pagedEngine = []
    this.api(this.id, e, 10)
  }
}
